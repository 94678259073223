import { Avatar, Button, HStack, Txt, VStack } from "@pomebile/primitives"
import { StickyBottom } from "../components/StickyBottom"
import { ScreenFrame } from "../components/ScreenFrame"
import { BankIcon, CentavoIcon, FindIcon } from "@pomebile/design-system"
import { PhBankPayer } from "@pomebile/pomelo-service-api"
import { bankPayerDepositDescription, phBankPayerTypeMap } from "../api/webRoutes"

interface RecipientVerificationConfirmBankAccountProps {
  bankPayer: PhBankPayer
  last4: string
  onDone: () => void
}

export function RecipientVerificationConfirmBankAccount({
  bankPayer,
  last4,
  onDone,
}: RecipientVerificationConfirmBankAccountProps) {
  const description = bankPayerDepositDescription[bankPayer] ? (
    <Txt variant="button2">
      Find a recent deposit with a reference name similar to{" "}
      <i>{bankPayerDepositDescription[bankPayer]}</i>.
    </Txt>
  ) : (
    <Txt variant="button2">Find a recent deposit with an amount below ₱1.00.</Txt>
  )

  return (
    <ScreenFrame>
      <VStack height="full">
        <VStack height="full" gap="xl3" padding={{ bottom: "xl2" }}>
          <VStack gap="xs">
            <Txt variant="headline2">Confirm your bank account</Txt>
            <Txt>To ensure the money is being safely sent to your account, follow these steps:</Txt>
          </VStack>

          <VStack gap="xl2">
            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<BankIcon fill="icon-brand" width={32} height={32} />} />
              <Txt variant="button2">
                Log in to your {phBankPayerTypeMap[bankPayer]} account (••{last4}) and view recent
                transactions.
              </Txt>
            </HStack>
            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<FindIcon fill="icon-brand" width={32} height={32} />} />
              <VStack gap="xs2">
                {description}
                <Txt variant="caption" color="text-caption">
                  The test deposit may take up to 24 hours to appear in your account.
                </Txt>
              </VStack>
            </HStack>
            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<CentavoIcon customFill="#F04679" width={32} height={32} />} />
              <Txt variant="button2">
                Locate the 2-digit centavo amount associated with the test deposit.
              </Txt>
            </HStack>
          </VStack>
          <VStack>
            <Txt>Enter the test deposit amount on the following page.</Txt>
          </VStack>
        </VStack>

        <StickyBottom>
          <Button onClick={onDone}>Continue</Button>
        </StickyBottom>
      </VStack>
    </ScreenFrame>
  )
}
