import { Avatar, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { CheckIcon } from "@pomebile/design-system"
import { PhBankPayer } from "@pomebile/pomelo-service-api"
import { phBankPayerTypeMap } from "../api/webRoutes"

interface RecipientVerificationSuccessProps {
  bankPayer: PhBankPayer
  last4: string
}

export function RecipientVerificationSuccess({
  bankPayer,
  last4,
}: RecipientVerificationSuccessProps) {
  return (
    <ScreenFrame>
      <VStack height="full" justifyContent="center" alignItems="center">
        <Avatar size="lg" variant="success" svg={<CheckIcon fill="icon-success" />} />
        <Txt variant="headline2" textAlign="center">
          Success!
        </Txt>
        <Txt textAlign="center">
          You can now receive bank transfers into your bank account ({phBankPayerTypeMap[bankPayer]}{" "}
          ••{last4}).
        </Txt>
      </VStack>
    </ScreenFrame>
  )
}
