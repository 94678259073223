import { Avatar, Button, HStack, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { StickyBottom } from "../components/StickyBottom"
import { AlertIcon, Callout } from "@pomebile/design-system"
import { RecipientVerificationVeriffSdkResponse, VeriffSdkError } from "../api/webRoutes"
import { useRecipientVerificationVeriff } from "../hooks/useRecipientVerificationVeriff"
import { segmentAnalyticsLogger } from "../utils/segment"

interface RecipientVerificationIntroProps {
  recipientName: string
  transferAmount: number
  transferExpiration: string
  veriffUrl?: string

  api: {
    startVeriffSdk: (url: string) => Promise<RecipientVerificationVeriffSdkResponse>
  }

  onDone: () => void
}

export function RecipientVerificationIntro({
  recipientName,
  transferAmount,
  transferExpiration,
  veriffUrl,
  api,
  onDone,
}: RecipientVerificationIntroProps) {
  const { buttonState, buttonText, sdkError, startSdk } = useRecipientVerificationVeriff({
    veriffUrl,
    onDone,
    api,
  })

  const expiration = new Date(transferExpiration).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  const handleClick = () => {
    segmentAnalyticsLogger.logEvent("Veriff intro continue clicked")
    startSdk()
  }

  return (
    <ScreenFrame>
      <VStack height="full" justifyContent="space-between">
        <VStack height="full" gap="xl4">
          <VStack gap="xs">
            <Txt variant="headline2">
              {recipientName} is sending you a ₱{transferAmount.toFixed(2)} bank transfer
            </Txt>
            <Txt>
              To start receiving bank transfers through Pomelo, please complete a quick, one-time
              confirmation of your identity and bank.
            </Txt>
          </VStack>

          <VStack gap="xl2">
            <HStack alignItems="center" gap="md">
              <Avatar
                size="lg"
                text={
                  <Txt variant="headline3" color="text-brand">
                    1
                  </Txt>
                }
              />
              <VStack>
                <Txt variant="overline2" color="text-brand">
                  Step 1
                </Txt>
                <Txt variant="subtitle2">Verify your identity</Txt>
                <Txt variant="body2">
                  Take a photo of your Government ID and a selfie to ensure safe transfers.
                </Txt>
              </VStack>
            </HStack>
            <HStack alignItems="center" gap="md">
              <Avatar
                size="lg"
                text={
                  <Txt variant="headline3" color="text-brand">
                    2
                  </Txt>
                }
              />
              <VStack>
                <Txt variant="overline2" color="text-brand">
                  Step 2
                </Txt>
                <Txt variant="subtitle2">Confirm your bank account</Txt>
                <Txt variant="body2">
                  Enter the amount of a small test deposit being sent to your account.
                </Txt>
              </VStack>
            </HStack>
          </VStack>

          <Txt variant="body2" color="text-caption">
            Complete these steps by {expiration} to receive the transfer.
          </Txt>
        </VStack>

        <StickyBottom>
          <VStack gap="md">
            {sdkError && (
              <VStack gap="md">
                <Callout variant="error">
                  <HStack alignItems="center" gap="sm">
                    <AlertIcon fill="icon-error" />
                    <Txt variant="body2" color="text-error">
                      {sdkError}
                    </Txt>
                  </HStack>
                </Callout>
              </VStack>
            )}

            <VStack gap="sm" alignItems="center">
              <Button state={buttonState} onClick={handleClick}>
                {buttonText}
              </Button>
            </VStack>
          </VStack>
        </StickyBottom>
      </VStack>
    </ScreenFrame>
  )
}
