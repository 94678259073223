import { Svg, Path } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const CentavoIcon = ({ width, height, customFill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" customFill="none">
      <Path
        d="M24 9.82641C22.3247 7.88876 19.8736 6.66667 17.1428 6.66667C12.0934 6.66667 8 10.8453 8 16C8 21.1547 12.0934 25.3333 17.1428 25.3333C19.8736 25.3333 22.3247 24.1112 24 22.1736M17.3333 6.66667V3M17.3333 29.5V25.3333"
        stroke={customFill}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        customFill="none"
      />
    </Svg>
  )
}
