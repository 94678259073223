import { Button, HStack, Link, Txt, VStack } from "@pomebile/primitives"
import { Dialog } from "../../components/Dialog"
import { DialogHeader } from "../../components/Dialog/DialogHeader"
import { CrossIcon } from "@pomebile/design-system"

interface TestDepositDialogProps {
  isOpen: boolean
  onClose: () => void
}

export const TestDepositDialog = ({ isOpen, onClose }: TestDepositDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogHeader>
        <HStack gap="xs" alignItems="center">
          <Txt variant="headline3">Where is the test deposit?</Txt>
        </HStack>
        <HStack width="auto" justifyContent="flex-end" alignItems="center">
          <Link onClick={onClose}>
            {/* Link adds unecessary height so we start at flex end */}
            <VStack justifyContent="flex-end">
              <CrossIcon width={24} height={24} />
            </VStack>
          </Link>
        </HStack>
      </DialogHeader>

      <VStack padding="xl" gap="xl">
        <Txt>
          The test deposit can take a little while to appear in your account, and may take up to 24
          hours depending on your bank.
        </Txt>
        <Txt>
          If it hasn’t appeared after this time, please check with your bank or have your loved one
          contact Pomelo's customer support.
        </Txt>
        <VStack padding={{ bottom: "sm" }}>
          <Button onClick={onClose}>Done</Button>
        </VStack>
      </VStack>
    </Dialog>
  )
}
